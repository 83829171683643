import ClientOnly from "@whitespace/gatsby-theme-wordpress-basic/src/components/ClientOnly";
import * as React from "react";

import SiteSearch from "../components/SiteSearch";

export default function SearchTemplate() {
  return (
    <ClientOnly fallback={null}>
      <SiteSearch />
    </ClientOnly>
  );
}
